<script setup lang="ts">
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'
import _ from 'lodash'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: Partial<IPChat.ChatMessage>
}>()

const openGMaps = () => {
  window.open(
    `https://www.google.com/maps/place/${props.message.message!.degreesLatitude},${props.message.message!.degreesLongitude}`,
  )
}
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-p-1 tw-group"
    :class="
      message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="message.sender_name && message.fromContact"
      :sender-name="message.sender_name"
      :from-contact="true"
      :media="true"
    />
    <MessageActions
      :message="message"
      :from-contact="message.fromContact!"
      :hide-forward="true"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <div class="tw-w-full tw-relative tw-overflow-hidden tw-rounded-md">
      <img src="@/../assets/images/map.webp" class="tw-max-w-96" />
      <div
        class="tw-absolute tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-backdrop-blur-[2px] tw-bg-base-300/50 tw-top-0 tw-left-0"
      >
        <button class="tw-daisy-btn tw-shadow-md" @click="openGMaps">
          <span class="material-symbols-outlined"> location_on </span>
          Visualizar no Mapa
        </button>
      </div>
    </div>
    <div class="tw-float-right">
      <MessageTime>
        {{ $formatTime(message.created_at) }}
      </MessageTime>
      <MessageStatus
        v-if="!message.fromContact"
        class="tw-pl-1"
        :status="Number(message.status)"
      />
    </div>
  </div>
  <Reactions
    :from-contact="message.fromContact!"
    :reactions="message.reactions"
  />
</template>

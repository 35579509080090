<script setup lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

const props = defineProps<{
  message: Partial<IPChat.ChatMessage>
  fromContact: boolean
  isMedia?: boolean
  enableDownload?: boolean
  hideForward?: boolean
}>()

defineEmits(['downloadCallback'])

const useAuth = useAuthStore()
const modalsStore = useModalsStore()
const activeChatStore = useActiveChatStore()

const isOpen = ref(false)
const msgMenu = ref<HTMLDetailsElement>()

function closeMenu() {
  msgMenu.value?.removeAttribute('open')
}

const forwardMessage = () => {
  modalsStore.forwardMessageMetadata.fromMessageKey = String(props.message.key)
  modalsStore.toogleForwardMessageModal()
}

const eraseMessage = () => {
  activeChatStore.revokeMessage({
    messageKey: props.message.key!,
    stateId: props.message.chat_state_id!,
  })
}

const replyMessage = () => {
  activeChatStore.replyMessage(props.message)
}

onMounted(() => {
  document.addEventListener('click', closeMenu)

  nextTick(() => {
    if (msgMenu.value) {
      msgMenu.value.addEventListener('toggle', (evt) => {
        // @ts-ignore
        if (evt.newState === 'open') {
          isOpen.value = true
        } else {
          isOpen.value = false
        }
      })
    }
  })
})
</script>
<template>
  <div
    class="tw-absolute tw-w-10 tw-h-10 tw-right-[-6px] tw-top-0 tw-z-[1]"
    :class="{
      'tw-opacity-0 group-hover:tw-opacity-100 group-hover:tw-rounded-tr-[6px]':
        !isOpen,
    }"
  >
    <details
      ref="msgMenu"
      class="tw-daisy-dropdown tw-text-base-content"
      :class="[
        fromContact ? 'tw-daisy-dropdown-right' : 'tw-daisy-dropdown-left',
      ]"
    >
      <summary
        class="tw-daisy-btn tw-daisy-btn-xs tw-daisy-btn-circle tw-m-1 tw-no-animation"
      >
        <ChevronDownIcon class="tw-size-4 tw-stroke-current tw-font-medium" />
      </summary>
      <ul
        class="tw-daisy-dropdown-content tw-daisy-menu tw-bg-base-100 tw-rounded-md tw-z-50 tw-w-52 tw-p-2 tw-shadow"
        :class="[fromContact ? 'tw-ml-2' : '']"
      >
        <li v-if="!hideForward">
          <a @click.prevent="forwardMessage">Encaminhar</a>
        </li>
        <li><a @click.prevent="replyMessage">Responder</a></li>
        <li v-if="enableDownload">
          <a @click.prevent="$emit('downloadCallback')">Baixar</a>
        </li>
        <li
          v-if="
            useAuth.allowDeleteMessage &&
            !message.fromContact &&
            !activeChatStore.currentContact.isGroup
          "
        >
          <a @click.prevent="eraseMessage">Apagar</a>
        </li>
      </ul>
    </details>
  </div>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'

import '@/../css/lightbox.css'
import VueEasyLightbox from 'vue-easy-lightbox/external-css'

import _ from 'lodash'
import MessageBottom from './Partials/MessageBottom.vue'
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: Partial<IPChat.ChatMessage>
}>()

const openModal = ref(false)

const imgRef = computed(() => {
  const caption = props.message.message?.caption
  if (_.isEmpty(caption)) {
    return [props.message.message?.media?.link as string]
  }

  return [
    {
      src: props.message.message?.media?.link,
      title: caption || '',
      alt: caption || '',
    },
  ]
})

const download = () => {
  downloadBuilder(
    String(props.message.message!.media?.link),
    props.message.message!.filename || `${uuidv4()}.jpg`,
  )
}
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-p-[3px] tw-rounded-lg tw-shadow-md tw-group"
    :class="
      props.message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="message.sender_name && props.message.fromContact"
      :sender-name="message.sender_name"
      :from-contact="true"
      :media="true"
    />
    <MessageActions
      :message="message"
      :from-contact="props.message.fromContact!"
      :enable-download="true"
      :is-media="true"
      @download-callback="download"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <div class="tw-relative">
      <div
        class="tw-rounded-md tw-overflow-hidden tw-cursor-pointer"
        @click="openModal = true"
      >
        <img
          :src="props.message.message!.media?.link"
          class="tw-min-h-36 tw-max-h-96 sm:tw-max-h-72"
        />
      </div>

      <div v-if="message.message?.caption" class="file-caption">
        <div class="tw-pt-1">
          <SenderName
            v-if="message.sender_name && !message.fromContact"
            :sender-name="message.sender_name"
            :from-contact="false"
            :media="true"
          />
          <span v-html="formatMarkdown(message.message.caption)" />
        </div>

        <MessageBottom class="!tw-mb-0">
          <MessageTime>
            {{ $formatTime(message.created_at) }}
          </MessageTime>
          <MessageStatus
            v-if="!message.fromContact"
            :status="Number(message.status)"
            :message-key="message.key"
          />
        </MessageBottom>
      </div>
      <div v-else class="bubble-bottom-caption shade">
        <MessageBottom :media="true">
          <MessageTime :media="true">
            {{ $formatTime(message.created_at) }}
          </MessageTime>
          <MessageStatus
            v-if="!message.fromContact"
            :status="Number(message.status)"
            :message-key="message.key"
          />
        </MessageBottom>
      </div>
    </div>
  </div>
  <Reactions
    :from-contact="props.message.fromContact!"
    :reactions="message.reactions"
  />

  <vue-easy-lightbox
    :visible="openModal"
    :imgs="imgRef"
    :rotate-disabled="true"
    :index="0"
    teleport="body"
    @hide="() => (openModal = false)"
  />
</template>
<style lang="css" scoped>
.bubble-bottom-caption {
  @apply tw-absolute tw-w-full tw-bottom-[0px] tw-overflow-hidden tw-h-[28px] tw-rounded-br-md tw-rounded-bl-md;
}

.shade {
  background: linear-gradient(
    to top,
    rgba(var(--overlay-bg), 0.6),
    rgba(var(--overlay-bg), 0)
  );
}
</style>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: Partial<IPChat.ChatMessage>
}>()

const download = () => {
  downloadBuilder(String(props.message.message!.media?.link), `${uuidv4()}.jpg`)
}

const fromContact = computed(() => props.message.fromContact!)
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-p-[3px] !tw-shadow-none !tw-bg-transparent tw-group"
    style="min-width: 210px; max-width: 250px"
    :class="
      fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="message.sender_name && fromContact"
      :sender-name="message.sender_name"
      :from-contact="true"
      :media="true"
    />
    <MessageActions
      :message="message"
      :from-contact="fromContact"
      :enable-download="true"
      :is-media="true"
      @download-callback="download"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />

    <figure class="tw-relative">
      <div class="message-content">
        <img :src="message.message!.media?.link" class="tw-object-scale-down" />
      </div>
      <div class="bubble-bottom-caption shade">
        <div class="tw-relative tw-right-1 tw-float-right tw-top-1">
          <MessageTime :media="true">
            {{ $formatTime(message.created_at) }}
          </MessageTime>
          <MessageStatus
            v-if="!fromContact"
            class="tw-pl-1"
            :status="message.status!"
          />
        </div>
      </div>
    </figure>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />
</template>
<style scoped>
.bubble-bottom-no-caption {
  @apply tw-relative tw-inline-flex tw-float-right tw-ml-1 tw-mr-0 tw-mb-[-5px] tw-mt-[-10px] tw-bottom-[0px] tw-items-center tw-pb-1;
}

.bubble-bottom-caption {
  @apply tw-absolute tw-w-full tw-bottom-[0px] tw-overflow-hidden tw-h-[28px] tw-rounded-br-md tw-rounded-bl-md;
}

.shade {
  background: linear-gradient(
    to top,
    rgba(var(--overlay-bg), 0.5),
    rgba(var(--overlay-bg), 0)
  );
}

.message-content {
  @apply tw-rounded-md tw-overflow-hidden;
}
</style>

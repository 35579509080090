<script setup lang="ts">
import _ from 'lodash'
import MessageBottom from './Partials/MessageBottom.vue'
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'
import DeletedMessage from './Partials/DeletedMessage.vue'
import { MessageStatus as Status } from '@/Enums/MessageStatus'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: Partial<IPChat.ChatMessage>
}>()

const currentLength = ref(120)
const totalSentences = ref(0)

const fromContact = computed(() => props.message.fromContact!)
const text = computed(() => formatMarkdown(String(props.message.message!.text)))
const showLoadMore = computed(() => currentLength.value < totalSentences.value)
const isOnlyEmoji = computed(() => ({
  isOnlyOneEmoji: props.message.message!.text
    ? isOnlyOneEmoji(props.message.message!.text)
    : false,
}))
const fullText = computed(() => {
  return text.value.split(' ').splice(0, currentLength.value).join(' ')
})

const loadMore = () => {
  const toLoad = 300
  if (currentLength.value + toLoad > totalSentences.value) {
    currentLength.value += totalSentences.value - currentLength.value
  } else {
    currentLength.value += toLoad
  }
}

onMounted(() => {
  const splittedText = text.value.split(' ')
  totalSentences.value = splittedText.length
})
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[5.5rem] !tw-pl-[9px] !tw-pr-2 tw-group"
    :class="[
      { '!tw-bg-info/90': message.is_transaction },
      { 'tw-daisy-chat-bubble-from-contact': fromContact },
      { 'tw-daisy-chat-bubble-from-attendant': !fromContact },
    ]"
  >
    <SenderName
      v-if="props.message.sender_name"
      :sender-name="props.message.sender_name"
    />

    <MessageActions
      v-if="
        !_.isEmpty(message.message) &&
        message.status !== Status.ERROR &&
        !message.is_transaction
      "
      :message="message"
      :from-contact="fromContact"
    />

    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <div class="tw-mb-[2px]">
      <div
        v-if="!_.isEmpty(message.message)"
        :class="[
          isOnlyEmoji,
          { 'tw-italic tw-font-bold': message.is_transaction },
        ]"
      >
        <span v-html="`${fullText}${showLoadMore ? '...' : ''}`" />
        <button
          v-if="showLoadMore"
          class="tw-text-wc-blue-400 hover:tw-underline"
          @click="loadMore"
        >
          Leia mais
        </button>
      </div>
      <DeletedMessage v-else />
    </div>

    <MessageBottom v-if="!_.isEmpty(message.message)">
      <MessageTime>
        {{ $formatTime(message.created_at) }}
      </MessageTime>
      <MessageStatus
        v-if="!message.fromContact && !message.is_transaction"
        :status="Number(message.status)"
        :message-key="message.key"
      />
    </MessageBottom>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />
</template>
